import Vue from 'vue'
import Vuex from 'vuex'

import login from './modules/login'
import licence from './modules/licence'
import key from './modules/rtc/key'
import grid from './modules/rtc/grid'
import button from './modules/rtc/button'
import mySip from './modules/solo/mySip'
import inesAudio from './modules/solo/ines-audio'
import file from './modules/rtc/file'
import users from './modules/users/users'
import event_lines from './modules/rtc/event_lines'
import lines from './modules/rtc/lines'

Vue.use(Vuex)

const store = new Vuex.Store(   {
    modules: {
        login,
        licence,
        key,
        grid,
        button,
        mySip,
        inesAudio,
        file,
        users,
        event_lines,
        lines
    }
})


export default store

