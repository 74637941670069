<template>
    <div>
      <div class="text-center d-flex justify-content-center mt-1" style="height: 300px">

          <Spectrum class="mr-1"/>
          <Clipboard class="" :class="IA.bin_is_hide ? 'small-clipboard' : 'w-25'"/>
      </div>
        <ButtonPannel/>
    </div>
</template>

<script>
    import ButtonPannel from "./ButtonPannel";
    import Clipboard from "./Clipboard";
    import Spectrum from "./Spectrum";
    export default {
        name: 'AudioEditor',
        components: {Spectrum, Clipboard, ButtonPannel},
        methods: {
        },
        mounted() {
        },
        props: {
            msg: String
        },
        computed: {
            IA() {
                return this.$store.state.inesAudio
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >


.small-clipboard {
  width: 13%
}

</style>
