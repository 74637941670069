<template>
    <b-list-group-item class="d-flex p-1 justify-content-between" :variant="IA.bin.data[this.index].status ? 'primary':''">
        <div class="d-flex align-items-center">
            <b-form-checkbox @focus="blurMe" @change="setBinStatus" v-model="IA.bin.data[index].status" name="check-button" switch/>
            <div v-if="!this.isModifyLabel">
              <div v-if="!IA.bin_is_hide">
                {{IA.bin.data[index].name}}
              </div>
              <div v-else>
                {{reducedName}}
              </div>
            </div>
            <b-form-input v-else @focusin="activeKeyboardShortcut(false)" @focusout="activeKeyboardShortcut(true)" v-model="IA.bin.data[index].name"/>
        </div>
        <b-btn-group v-if="!IA.bin_is_hide">
          <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.play')" style="background-color: green" v-if="IA.bin.data[this.index].status && !IA.bin_is_playing" @click="playBin">
            <b-icon icon="play-fill" aria-hidden="true"/>
          </b-btn>
          <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.stop')" variant="danger" v-if="IA.bin.data[this.index].status && IA.bin_is_playing" @click="stopBin">
            <b-icon icon="stop-fill" aria-hidden="true"/>
          </b-btn>
            <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.edit')" variant="primary" v-if="!this.isModifyLabel" @click="editLabel">
              <b-icon icon="pencil" aria-hidden="true"/>
            </b-btn>
            <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.finish-edit')" variant="success" v-else @click="finishLabelEdit">
              <b-icon icon="check2" aria-hidden="true"/>
            </b-btn>
            <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.move-up')" variant="info" @click="switchBinUp">
                <b-icon icon="arrow-up" aria-hidden="true"/>
            </b-btn>
            <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.move-down')" variant="info" @click="switchBinDown">
                <b-icon icon="arrow-down" aria-hidden="true"/>
            </b-btn>
            <b-btn @focus="blurMe" v-b-tooltip.hover :title="$t('solo_editor.clipboard.delete')" variant="danger" @click="destroyComponent()">
                <b-icon icon="trash" aria-hidden="true"/>
            </b-btn>
        </b-btn-group>
    </b-list-group-item>
</template>

<script>
    export default {
        name: 'AudioEditor',
        data() {
            return {
                isModifyLabel: false,
                actual_style: "",
                reducedName: ""
            }
        },
        methods: {
            blurMe(e) {
              e.currentTarget.blur()
            },
            activeKeyboardShortcut(status) {
              this.$store.commit('inesAudio/activeKeyboardShortcut', status)
            },
            reduceBinName() {
              let name = this.IA.bin.data[this.index].name
              if (name.length > 14) {
                name = name.slice(0, 14)
                name = name + "..."
              }
              this.reducedName = name
            },
            editLabel() {
                this.isModifyLabel = true;
            },
            playBin() {
                this.$store.commit('inesAudio/playBin')
            },
            stopBin() {
                this.$store.commit('inesAudio/stopBin')
            },
            finishLabelEdit() {
                this.isModifyLabel = false;
                this.reduceBinName()
            },
            switchBinDown() {
              if (!this.isModifyLabel)
                this.$store.commit('inesAudio/switchBinDown', {
                    index: parseInt(this.index)
                })
            },
            switchBinUp() {
              if (!this.isModifyLabel)
                this.$store.commit('inesAudio/switchBinUp', {
                    index: parseInt(this.index)
                })
            },
            setBinStatus() {
                this.$store.commit('inesAudio/setBinStatus', parseInt(this.index))
            },
            destroyComponent() {
                this.$store.commit('inesAudio/delBin', parseInt(this.index))
            },
        },
        mounted() {
          this.reduceBinName()
        },
        props: [
            "index"
        ]
        , computed: {
            IA() {
                return this.$store.state.inesAudio
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
