import Vue from 'vue'
import Vuex from 'vuex'
//import i18n from 'vue-i18n'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import * as SIP from 'sip.js'



import $ from 'jquery'
import store from './vuex/store'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
//import VueResource from "vue-resource";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import '@mdi/font/css/materialdesignicons.css'


//Vue.loadScript("/js/sip-0.17.1.min.js")

export var ConfJson = null, version = "v1.4.0";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex)
//Vue.use(i18n)
Vue.use(VueMeta)
Vue.use(PerfectScrollbar)
Vue.use(SIP)

//Vue.use(VueResource)

Vue.component('loading',{ template: '<div>Loading!</div>'})

new Vue({
    data: {loading: true},

    methods: {
        getConfJson: function (callback) {

            $.getJSON('setup.json',function (data) {
                ConfJson = data
                if (callback) {
                    callback()
                }
            }).done().fail( function (e) {
                if (e.status === 404) {
                   $.getJSON('dev.setup.json',function (data) {
                        ConfJson = data
                       if (callback) {
                           callback()
                       }
                    }).done().fail( function (e) {
                        if (e.status === 404) {
                            alert("Fichier de configuration Manquant !")
                        }
                    })
                }
            })
        },
    },

    beforeMount: function () {
        let MyComponent = this;
        this.getConfJson(function () {
            let old_lang = localStorage.getItem('locale')
            if (old_lang === undefined || old_lang === null || old_lang === "null" || old_lang.length === 0) {
                MyComponent.$i18n.locale = ConfJson.default_language
                localStorage.setItem('locale', ConfJson.default_language)
            }
        });
    },

    mounted: function () {
        let MyComponent = this;
        localStorage.openpages = Date.now();
        var onLocalStorageEvent = function(e){
            if(e.key === "openpages"){
                // Listen if anybody else is opening the same page!
                localStorage.page_available = Date.now();
            }
            if (e.key === "page_available") {
                alert(MyComponent.$i18n.t('login.already_opened'));
            }
        };
        window.addEventListener('storage', onLocalStorageEvent, false);
        let token = localStorage.getItem('token');
//    console.log(router.currentRoute)
        if ((token === null || token.length === 0) && router.currentRoute.path !== "/login") {
            router.push('/login')
            //window.location.href = '/login'
        } else if (router.currentRoute.path !== "/login") {
            if (getRoute(router.currentRoute.path) !== router.currentRoute.path) {
                router.push(getRoute(router.currentRoute.path))
            }

            localStorage.setItem('route', router.currentRoute.path)
        }

    },

    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

function getRoute(currentRoute) {
    //FUNCTION AUSSI PRESENTE DANS LE LOGIN.JS ! // TODO : A RELIER OU UPDATE !
    let lic = getRouteFormLicense()
    if ((currentRoute === "/solo" && lic.Solo) ||
        (currentRoute === "/speaker" && lic.Speaker) ||
        (currentRoute === "/admin" && lic.Admin) ||
        (currentRoute === "/screen" && lic.Screen) ||
        (currentRoute === "/games" && lic.Games) ||
        (currentRoute === "/admin" && lic.Admin) ||
        (currentRoute === "/standard" && lic.Standard)
    ) {
        return currentRoute
    } else {
        return lic.DefaultRoute;
    }
}

function getRouteFormLicense() {
    //TODO FAIRE LES PAGES CORRECTEMENT !
    let ret ={Home:false, Admin:false,Rtc:false, Solo:false, Standard: false,Screen: false, Speaker:false,  Games:false, DefaultRoute : "/" }
    let lic = JSON.parse(localStorage.getItem("license"));
    for (let i = 0; i < lic.length; i++) {
        if (lic[i].Label === "nb_line" && lic[i].Value > 0) {
            ret.DefaultRoute = "/rtc";
            ret.Rtc = true
            return ret
        }
        if (lic[i].Label === "nb_standard" && lic[i].Value > 0) {
            ret.DefaultRoute = "/standard";
            ret.Standard = true
            return ret
        }
        if (lic[i].Label === "nb_solo" && lic[i].Value > 0) {
            ret.DefaultRoute = "/solo";
            ret.Solo = true
            return ret
        }
        if (lic[i].Label === "nb_screen" && lic[i].Value > 0) {
            ret.DefaultRoute = "/screen";
            ret.Screen = true
            return ret
        }
        if (lic[i].Label === "nb_speaker" && lic[i].Value > 0) {
            ret.DefaultRoute = "/speaker";
            ret.Speaker = true
            return ret
        }
        if (lic[i].Label === "nb_games" && lic[i].Value > 0) {
            ret.DefaultRoute = "/games";
            ret.Games = true
            return ret
        }
    }
    return ret
}


