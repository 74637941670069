import $ from 'jquery'
import WaveSurfer from "wavesurfer.js";
import AudioEditor from "@/assets/solo/wavesurfer_editor"
import RegionsPlugin from "wavesurfer.js/src/plugin/regions/index.js";
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';
import MarkerPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.markers.js';
import Vue from "vue"
import path from 'path'
import i18n from '@/i18n';
//var TimelinePlugin = WaveSurfer.timeline;

const state = {
    bin: {data: [], id: 1},
    history: {data: [], idx: -1},
    trash: {data: [], idx: 1},
    my_spectrum: null,
    timer : {current_time: 0, total_time: 0, duration_before_edit: 0, region_start: 0, region_end: 0, region_duration: 0},
    timer_print: {current_time: "", total_time: "", duration_before_edit: "", region_start: "", region_end: "", region_duration: ""},
    dual_channel: {input: {}, splitter: {}, merger: {}, left_gain:{}, right_gain: {}},
    channel_status: {left: true, right: true, nbrOfChannel: 0},
    play_options: {speed: 1, dodge_region: false},
    zoom_value: 0,
    wave_editor: {},
    vu_meter: {height1: 100, height2: 100, actual_peak1 : 0, actual_peak2: 0},
    file_name: "",
    buffer_database: {},
    audio_peaks: {},
    keyboard: {ctrl_key_ready: false, action_complete: false},
    need_ram_reset: false,
    is_history_load: false,
    spectrum_is_loading: false,
    bin_is_playing: false,
    bin_is_hide: false,
    action_finished: true,
    history_action_finished: true,
    is_file_loaded: false,
    is_shortcut_active: true,
}

const mutations = {
    ImAFunctionInVUEX(){
        $.getJSON("jesuisunerequettejquery.json").done(function () {

        }).fail(function (e) {
            console.log(e)
        })
    },

    //INIT FUNCTIONS

    initState(state) {
        window.indexedDB.deleteDatabase("BufferData")
        state.bin =  {data: [], id: 1};
        state.history = {data: [], idx: -1};
        state.trash = {data: [], idx: 1};
        state.timer = {current_time: 0, total_time: 0, duration_before_edit: 0, region_start: 0, region_end: 0, region_duration: 0};
        state.timer_print =  {current_time: "", total_time: "", duration_before_edit: "", region_start: "", region_end: "", region_duration: ""};
        state.dual_channel = {input: {}, splitter: {}, merger: {}, left_gain:{}, right_gain: {}};
        state.play_options = {speed: 1, dodge_region: false};
        state.zoom_value = 0;
        state.wave_editor = {};
        state.vu_meter = {height1: 100, height2: 100, actual_peak1 : 0, actual_peak2: 0};
        state.file_name = "";
        state.buffer_database = {};
        state.audio_peaks = {};
        state.keyboard = {ctrl_key_ready: false, action_complete: false};
        state.need_ram_reset = false;
        state.is_history_load = false;
        state.spectrum_is_loading = false;
        state.bin_is_playing = false;
        state.bin_is_hide = false;
        state.action_finished = true;
        state.is_file_loaded = false;
        state.is_shortcut_active = true;
        state.channel_status = {left: true, right: true, nbrOfChannel: 0};
    },
    createSpectrum(state) {
            state.my_spectrum = WaveSurfer.create({
                container: "#audio-spectrum",
                waveColor: 'green',
                progressColor: "blue",
                backgroundColor: "white",
                splitChannels: true,
                minPxPerSec: 20,
                height: 107,
                autoCenter: true,
                backend: 'WebAudio',
                mediaType: 'audio',
                splitChannelsOptions: {
                    overlay: false,
                    channelColors: {
                        0: {
                            progressColor: 'rgba(0,128, 2, 0)',
                            waveColor: 'rgba(222, 40, 39, 0.8)',
                        },
                        1: {
                            progressColor: 'rgba(0,128, 2, 0)',
                            waveColor: 'rgba(222, 40, 39, 0.8)',
                        }
                    }
                },
                plugins: [RegionsPlugin.create({
                    dragSelection: true,
                }), TimelinePlugin.create({
                    container:'#audio-timeline'
                }) ,CursorPlugin.create({
                    showTime: true,
                    opacity: 1,
                    customShowTimeStyle: {
                        'background-color': '#000',
                        color: '#fff',
                        padding: '2px',
                        'font-size': '10px'
                    }
                }), MarkerPlugin.create({
                    markers: []
                })]
            })
            state.my_spectrum.markers.add({time: 0, label: "", color: 'black', position: 'top'})
        //alert(JSON.stringify(state.my_spectrum))
    },
    loadSpectrumFromFile(state, file_path) {

        mutations.initState(state);
        createDB(state);
        state.file_name = path.basename(file_path);
        //alert(JSON.stringify(state.my_spectrum))
        state.my_spectrum.load(file_path);
        state.spectrum_is_loading = true;
        state.wave_editor = new AudioEditor(state.my_spectrum);
        state.my_spectrum.on('ready', function () {
            generateAudioBackend();
            mutations.updateChannelSettings(state);
            if (state.timer.duration_before_edit === 0) {
                state.timer.duration_before_edit = Math.round(state.my_spectrum.getDuration() * 100) / 100;
                state.timer_print.duration_before_edit = createTimeDisplay(state.timer.duration_before_edit)
            }
            state.channel_status.nbrOfChannel = state.my_spectrum.ActiveChannels.length;
            state.audio_peaks = state.my_spectrum.backend.getPeaks((state.my_spectrum.getDuration() * state.my_spectrum.params.minPxPerSec * state.my_spectrum.params.pixelRatio));
            if (state.is_history_load === true)
                state.is_history_load = false;
            else
                addHistory(state, state.my_spectrum.backend.buffer);
            state.need_ram_reset = true;
            state.spectrum_is_loading = false;
            state.history_action_finished = true;
            state.is_file_loaded = true;

        });
        eventManager(state)
    },

    // AUDIO PLAYING FUNCTIONS

    play(state, props) {
        if (props.spectrum === null)
            return;
        state.play_options.dodge_region = false;
        props.spectrum.pause();
        props.spectrum.setPlaybackRate(props.speed);
        props.spectrum.play();
    },
    stop(state) {
        if (state.my_spectrum === null)
            return;
        state.my_spectrum.pause();
        mutations.cursorToTimer(state, state.my_spectrum.markers.markers[0].time);
    },
    playWithoutRegion(state) {
        if (state.my_spectrum === null)
            return;
        state.play_options.dodge_region = true;
        state.my_spectrum.pause();
        state.my_spectrum.setPlaybackRate(state.play_options.speed);
        state.my_spectrum.play();
    },
    playRegion(state) {
        if (state.my_spectrum === null)
            return;
        state.play_options.dodge_region = false;
        mutations.cursorToRegionStart(state,0);
        let regions = state.my_spectrum.regions.list;
        if(Object.keys(regions).length > 0){
            regions[Object.keys(regions)[0]].play()
        }
    },

    skipSpectrum(state, seconds) {
        if (state.my_spectrum === null)
            return;
        state.my_spectrum.skip(seconds)
    },
    cursorToRegionStart(state,seconds_delay){
        if (state.my_spectrum === null)
            return;
        let regions = state.my_spectrum.regions.list;
        if(Object.keys(regions).length > 0) {
            mutations.cursorToTimer(state, (regions[Object.keys(regions)[0]].start + seconds_delay))
        }
    },

    // EDIT FUNCTIONS

    copyRegion(state) {
        if (state.my_spectrum === null)
            return;
        if (Object.keys(state.my_spectrum.regions.list).length > 0) {
            if (state.action_finished === false)
                return;
            state.action_finished = false;
            let region = state.my_spectrum.regions.list[Object.keys(state.my_spectrum.regions.list)[0]];

            let copy = state.wave_editor.copy({start: region.start, end: region.end}, state.channel_status);
            mutations.addBin(state, {name: i18n.t('solo_editor.clipboard.default-name') + state.bin.id.toString(), save_copy: copy})
        }
    },
    cutRegion(state, props) {
        if (state.my_spectrum === null)
            return;
        if (Object.keys(state.my_spectrum.regions.list).length > 0) {
            mutations.stop(state)
            let keys = Object.keys(state.my_spectrum.regions.list);
            let region = {};

            keys.forEach((key) => {
                region = state.my_spectrum.regions.list[key];
            });
            if (!region || state.action_finished === false)
                return;
            state.action_finished = false;
            state.spectrum_is_loading = true;
            mutations.cursorToTimer(state, region.start);
            let res = state.wave_editor.cut({
                start: region.start,
                end: region.end,
                withBlank: props.withBlank,
                channelStatus: state.channel_status
            });
            if (props.toBin === true)
                mutations.addBin(state, {name: i18n.t('solo_editor.clipboard.default-name') + state.bin.id.toString(), save_copy: res.cutSelection});
            else {
                mutations.addTrash(state, {my_blob: res.cutSelection})
            }
        }
    },
    trimRegion(state) {
        if (state.my_spectrum === null)
            return;
        if (Object.keys(state.my_spectrum.regions.list).length > 0) {
            mutations.stop(state)
            if (state.action_finished === false)
                return;
            state.action_finished = false;

            let keys = Object.keys(state.my_spectrum.regions.list);
            let region = {};
            keys.forEach((key) => {
                region = state.my_spectrum.regions.list[key];
            });
            if (!region) return;
            state.spectrum_is_loading = true;
            let res = state.wave_editor.trim({
                start: region.start,
                end: region.end,
                channelStatus: state.channel_status
            });
            mutations.addBin(state, {name: i18n.t('solo_editor.clipboard.default-name') + state.bin.id.toString(), save_copy: res.newAudioBuffer})
            mutations.cursorToTimer(state, 0)
        }
    },
    pasteRegion(state) {
        if (state.action_finished === false || state.my_spectrum === null || state.bin.data.length === 0)
            return;
        state.action_finished = false;
        state.bin.data.forEach((bins) => {
            if (bins.status === true) {
                mutations.stop(state)
                getBlobStored(state, "actualBuffer", "BinStore", function (result) {
                    let offlineAudioContext = state.my_spectrum.backend.ac;
                    let newAudioBuffer = offlineAudioContext.createBuffer(
                        result.nbrOfChannel,
                        result.length,
                        result.sampleRate);
                    for (let i = 0; i < result.nbrOfChannel; i++) {
                        let bufferData = newAudioBuffer.getChannelData(i)
                        bufferData.set(result.data[i])
                    }
                    state.spectrum_is_loading = true;
                    state.wave_editor.paste({cutSelection: newAudioBuffer, channelStatus: state.channel_status}, function() {
                        state.action_finished = true;
                    });
                })
            }
        });
    },

    // CHANNEL MANAGER

    channelButtonClick(state, channel_nbr) {
        if (channel_nbr === 0) {
            state.channel_status.left = !state.channel_status.left;
            mutations.updateChannelSettings(state);
            if (state.channel_status.left === true) {
                state.my_spectrum.params.splitChannelsOptions.channelColors["0"].waveColor = 'rgba(222, 40, 39, 0.8)';
            }
            else {
                state.my_spectrum.params.splitChannelsOptions.channelColors["0"].waveColor = "grey";
            }
        }
        if (channel_nbr === 1) {
            state.channel_status.right = !state.channel_status.right;
            mutations.updateChannelSettings(state);
            if (state.channel_status.right === true) {
                state.my_spectrum.params.splitChannelsOptions.channelColors["1"].waveColor = 'rgba(222, 40, 39, 0.8)';
            }
            else {
                state.my_spectrum.params.splitChannelsOptions.channelColors["1"].waveColor = "grey";
            }
        }
        state.my_spectrum.drawBuffer();
    },
    setChannelsVolume(state, volumes) {
        state.dual_channel.left_gain.gain.value = volumes.left;
        state.dual_channel.right_gain.gain.value = volumes.right;
    },
    updateChannelSettings(state){
        state.dual_channel.splitter = state.my_spectrum.backend.ac.createChannelSplitter(2);
        state.dual_channel.merger = state.my_spectrum.backend.ac.createChannelMerger(2);
        state.dual_channel.left_gain = state.my_spectrum.backend.ac.createGain();
        state.dual_channel.right_gain = state.my_spectrum.backend.ac.createGain();

        if (state.channel_status.left === true && state.channel_status.right === false) {
            state.dual_channel.splitter.connect(state.dual_channel.left_gain, 0);
            state.dual_channel.left_gain.connect(state.dual_channel.merger, 0, 0);
            state.dual_channel.right_gain.connect(state.dual_channel.merger, 0, 1);
        }
        else if (state.channel_status.left === false && state.channel_status.right === true) {
            state.dual_channel.splitter.connect(state.dual_channel.left_gain, 0)
            state.dual_channel.splitter.connect(state.dual_channel.right_gain, 1)
            state.dual_channel.left_gain.connect(state.dual_channel.merger, 0, 0);
            state.dual_channel.right_gain.connect(state.dual_channel.merger, 0, 1);
            state.dual_channel.left_gain.gain.value = 0;
        }
        else {
            state.dual_channel.splitter.connect(state.dual_channel.left_gain, 0);
            state.dual_channel.splitter.connect(state.dual_channel.right_gain, 1);
            state.dual_channel.left_gain.connect(state.dual_channel.merger, 0, 0);
            state.dual_channel.right_gain.connect(state.dual_channel.merger, 0, 1);
        }
        if (state.channel_status.left === false && state.channel_status.right === false) {
            state.dual_channel.left_gain.gain.value = 0;
            state.dual_channel.right_gain.gain.value = 0;
        }
        const panner = state.my_spectrum.backend.ac.createPanner();
        state.dual_channel.merger.connect(panner);

        state.my_spectrum.backend.setFilters([state.dual_channel.splitter,state.dual_channel.left_gain, state.dual_channel.right_gain, state.dual_channel.merger]);
    },

    //BIN FUNCTIONS

    switchBinUp(state, props) {
        console.log(props.index)
        if (props.index === 0)
            return;
        Vue.nextTick(function () {
            let tmp = state.bin.data[props.index];
            let tmp2 = state.bin.data[props.index - 1]
            Vue.set(state.bin.data, props.index, tmp2)
            Vue.set(state.bin.data, props.index - 1, tmp)
        })
    },
    switchBinDown(state, props) {
        console.log(props.index)
        if (props.index === state.bin.data.length-1)
            return;
        Vue.nextTick(function () {
            let tmp = state.bin.data[props.index];
            let tmp2 = state.bin.data[props.index + 1]
            Vue.set(state.bin.data, props.index, tmp2)
            Vue.set(state.bin.data, props.index +1, tmp)
        })
    },
    setBinStatus(state, index) {
        state.action_finished = false
        state.bin.data.forEach((element) => {
                element.status = false;
        });
        state.bin.data[index].status = true;
        getBlobStored(state, state.bin.data[index].id, "BinStore", function (result) {
            document.getElementById("binPlayer").src=URL.createObjectURL(result);
            storeBufferFromBlob(result)
            state.action_finished = true;
        })
    },
    playBin() {
        document.getElementById('binPlayer').play()
    },
    stopBin() {
        document.getElementById('binPlayer').pause()
        document.getElementById('binPlayer').currentTime = 0.0;
    },
    addBin(state, props) {
        state.bin.data.forEach((element) => {
                element.status = false;
        });
        if (props.name.length === 0) {
            props.name = "new-track-" + Math.floor(Math.random() * 100000)
        }
        Vue.nextTick(function () {
            storeBlob(state, props.save_copy, "BinStore", "Bin"+state.bin.id.toString())
            state.bin.data.push({name: props.name, id: "Bin"+state.bin.id.toString() ,status: false, is_play: false})
            mutations.setBinStatus(state, state.bin.data.length-1)
            state.bin.id = state.bin.id + 1;
        })
    },
    delBin(state, index){
        let wasTrue = state.bin.data[index].status
        getBlobStored(state, state.bin.data[index].id, "BinStore", function (result) {
            let DBOpenRequest = window.indexedDB.open("BufferData", 4);
            DBOpenRequest.onsuccess = function() {
                state.buffer_database = DBOpenRequest.result;
                let store = state.buffer_database.transaction("TrashBinStore", 'readwrite').objectStore("TrashBinStore")
                store.put(result, "TrashBin"+state.trash.idx.toString())
                state.trash.data.push({id: "TrashBin"+state.trash.idx.toString() ,status: false, is_play: false})
                state.trash.idx = state.trash.idx + 1;
            };
        })
        state.bin.data.splice(index, 1)
        if (wasTrue && state.bin.data.length > 0)
            mutations.setBinStatus(state, state.bin.data.length-1)
    },
    addTrash(state, props){
        storeBlob(state, props.my_blob, "TrashBinStore", "TrashBin"+state.trash.idx.toString())
        state.trash.data.push({id: "TrashBin"+state.trash.idx.toString() ,status: false, is_play: false})
        state.trash.idx = state.trash.idx + 1;
    },
    hideOrRevealBin(state) {
        state.bin_is_hide = !state.bin_is_hide;
        state.need_ram_reset = true;
    },

    // HISTORY FUNCTIONS

    undoAction(state) {
        if (state.history.idx >= 1 && state.history.data.length > 1) {
            if (state.history_action_finished === false)
                return;
            state.history_action_finished = false;
            getBlobStored(state, state.history.data[state.history.idx-1].id, "HistoryStore", function (result) {
                state.is_history_load = true;
                state.my_spectrum.clearRegions()
                state.my_spectrum.loadBlob(result)
                state.history.idx= state.history.idx - 1
            })
        }
    },
    redoAction(state) {
        if (state.history.idx < state.history.data.length-1 && state.history.data.length > 0) {
            if (state.history_action_finished === false)
                return;
            state.history_action_finished = false;
            state.history.idx= state.history.idx + 1
            getBlobStored(state, state.history.data[state.history.idx].id, "HistoryStore", function (result) {
                state.is_history_load = true;
                state.my_spectrum.clearRegions()
                state.my_spectrum.loadBlob(result)
            })
        }
    },

    //OTHER

    zoom(state, value) {
        if (state.my_spectrum !== null) {
            state.zoom_value = state.zoom_value + value;
            if (state.zoom_value < 0)
                state.zoom_value = 0;
           let my_val = (1047/*(spectrum width)*/ / state.my_spectrum.getDuration()) + (state.zoom_value);
            state.my_spectrum.zoom((my_val + ((state.zoom_value) * ((30*(my_val*2))/100))));
        }
    },
    stereoToMonoBuffer(buffer) {
        let newBuffer = state.my_spectrum.backend.ac.createBuffer(
            buffer.numberOfChannels,
            (buffer.length +1),
            buffer.sampleRate);
        let channel1 = buffer.getChannelData(0);
        let channel2 = buffer.getChannelData(1);
        let newBufferChannel1 = newBuffer.getChannelData(0);
        let newBufferChannel2 = newBuffer.getChannelData(1);

        for (let i = 0; i !== buffer.length; i++) {
            newBufferChannel1[i] = ((channel1[i] + channel2[i]) * 0.5)
            newBufferChannel2[i] = ((channel1[i] + channel2[i]) * 0.5)
        }
        return newBuffer;
    },
    downloadAudio(state) {
        if (state.my_spectrum === null)
            return;
        let my_blob = state.wave_editor.bufferToWave(mutations.stereoToMonoBuffer(state.my_spectrum.backend.buffer), 0, state.my_spectrum.backend.buffer.length)
        var url = (window.URL || window.webkitURL).createObjectURL(my_blob);

        var a = document.createElement( 'a' );
        a.href = url;
        a.download = state.file_name;
        a.style.display = 'none';
        document.body.appendChild( a );
        a.click();
    },
    putNewMarker(state, timer) {
        state.my_spectrum.markers.add({time: timer, label: "", color: 'black', position: 'top'})
    },
    putRegionStart(state) {
        if (state.my_spectrum === null)
            return;
        let regions = state.my_spectrum.regions.list;
        if (Object.keys(regions).length > 0)
        {
            let actualRegion = regions[Object.keys(regions)[0]]
            mutations.clearAllRegions(state)
            state.my_spectrum.addRegion({start: state.timer.current_time, end: actualRegion.end})
            if (state.timer.current_time > regions[Object.keys(regions)[0]].end || (actualRegion.start === actualRegion.end))
                state.my_spectrum.addRegion({start: state.timer.current_time, end: state.timer.current_time})
        }
        else
            state.my_spectrum.addRegion({start: state.timer.current_time, end: state.timer.current_time})
    },
    putRegionEnd(state) {
        if (state.my_spectrum === null)
            return;
        let regions = state.my_spectrum.regions.list;
        if (Object.keys(regions).length > 0 && state.timer.current_time > regions[Object.keys(regions)[0]].start)
        {
            let actualRegion = regions[Object.keys(regions)[0]]
            mutations.clearAllRegions(state)
            state.my_spectrum.addRegion({start: actualRegion.start, end: state.timer.current_time})
        }
    },
    toNextMark(state) {
        if (state.my_spectrum.markers.markers.length > 0) {
            let nextMark = state.my_spectrum.markers.markers[0];
            state.my_spectrum.markers.markers.forEach((mark) => {
                if (mark.time > state.timer.current_time && mark.time < nextMark.time)
                    nextMark = mark;
            });
            mutations.cursorToTimer(state, nextMark.time)
        }
    },
    clearAllRegions(state) {
        if (state.my_spectrum !== null)
            state.my_spectrum.clearRegions()
    },
    cursorToTimer(state, timer) {
        let pos = (100 * (timer / state.timer.total_time)/100)
        if (pos < 0)
            pos = 0
        if (pos > 1)
            pos = 1
        state.my_spectrum.seekTo(pos)
    },
    stopEdit(state) {
        if (state.my_spectrum === null)
            return;
        mutations.initState(state)
        state.my_spectrum.empty()
        state.my_spectrum.destroy()
        state.my_spectrum = null;
        state.is_file_loaded = false;
    },
    activeKeyboardShortcut(state, status) {
        state.is_shortcut_active = status;
    },
    createAudioBufferFromStore(state, storeName, key) {
        getBlobStored(state, key, storeName, function (result) {
            let offlineAudioContext = this.wavesurfer.backend.ac;
            let newAudioBuffer = offlineAudioContext.createBuffer(
                result.nbrOfChannel,
                result.length,
                result.sampleRate);
            for (let i = 0; i < result.nbrOfChannel; i++) {
                let bufferData = newAudioBuffer.getChannelData(i)
                bufferData.set(result.data[i])
            }
        })
    },
    changeBinLabel(state, props) {
        state.bin[props.index].name = props.label
    },
}

export default {
    namespaced: true,
    state,
    mutations
}

//DATABASE STORAGE

function createDB(state) {
    let request = window.indexedDB.open("BufferData", 4);
    request.onerror = function() {
        alert("Pourquoi ne permettez-vous pas à ma web app d'utiliser IndexedDB?!");
    };
    request.onupgradeneeded = function(event) {
        state.buffer_database = event.target.result;
        state.buffer_database.createObjectStore("BinStore")
        state.buffer_database.createObjectStore("TrashBinStore")
        state.buffer_database.createObjectStore("HistoryStore")
    };
}
function storeBlob(state, audioBuffer, storeName, key) {
    let my_blob = state.wave_editor.bufferToWave(audioBuffer, 0, audioBuffer.length)
    for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
        let DBOpenRequest = window.indexedDB.open("BufferData", 4);
        DBOpenRequest.onsuccess = function() {
            state.buffer_database = DBOpenRequest.result;
            let store = state.buffer_database.transaction(storeName, 'readwrite').objectStore(storeName)
            store.put(my_blob, key)
        };
    }
}
function getBlobStored(state, key, storeName, callback) {
    let DBOpenRequest = window.indexedDB.open("BufferData", 4);
    DBOpenRequest.onsuccess = function() {
        state.buffer_database = DBOpenRequest.result;
        let getRequest = state.buffer_database.transaction(storeName, 'readwrite').objectStore(storeName).get(key)
        getRequest.onsuccess = function () {
            if (callback) {
                callback(getRequest.result)
            }
        }
    };
}
function storeBufferFromBlob(blob) {
    let fileReader = new FileReader();
    let arrayBuffer;

    fileReader.onloadend = () => {
        arrayBuffer = fileReader.result;
        let offlineAudioContext = state.my_spectrum.backend.ac
        offlineAudioContext.decodeAudioData(arrayBuffer, function(buffer) {
                let DBOpenRequest = window.indexedDB.open("BufferData", 4);
                DBOpenRequest.onsuccess = function() {
                    state.buffer_database = DBOpenRequest.result;
                    let store = state.buffer_database.transaction("BinStore", 'readwrite').objectStore("BinStore")
                    store.put(createBufferData(buffer), "actualBuffer")
                };
            },
            function(e){ console.log("Error with decoding audio data" + e.err); });
    }
    fileReader.readAsArrayBuffer(blob);
    //let offlineAudioContext = this.wavesurfer.backend.ac

}
function createBufferData(buffer) {
    let dataArray = [];
    for (let i = 0; i < buffer.numberOfChannels; i++)
        dataArray.push(buffer.getChannelData(i))
    return {length: buffer.length, sampleRate: buffer.sampleRate, nbrOfChannel: buffer.numberOfChannels, data: dataArray};
}

//HISTORY

function addHistory(state, buffer) {
    Vue.nextTick(function () {
        state.history.data.push({id: "History"+(state.history.idx+2).toString()})
        storeBlob(state, buffer, "HistoryStore", "History"+(state.history.idx+2).toString())
        state.history.idx = state.history.idx + 1;
        if (state.history.idx < state.history.data.length-1)
            state.history.data.splice(state.history.idx+1, state.history.data.length)
    })
}

//EVENTS

function scrollZoom(state) {
    window.document.getElementById("audio-spectrum").addEventListener('wheel', function(event)
    {
        if (event.deltaY < 0)
        {
            mutations.zoom(state, 1)
        }
        else if (event.deltaY > 0)
        {
            mutations.zoom(state, -1)
        }
    });
}
function keyboardEvent(state) {
    document.addEventListener('keydown', (event) => {
        if (state.is_shortcut_active) {
            if (event.key === 'Control') {
                state.keyboard.ctrl_key_ready = true;
            }
            if (state.keyboard.ctrl_key_ready === false && state.keyboard.action_complete === false && (event.key === 'i' || event.key === 'o'
                || event.code === 'Space' || event.code === 'ArrowLeft' || event.code === 'ArrowRight' || event.code === 'Delete')) {
                if (event.key === 'i')
                    mutations.putRegionStart(state)
                if (event.key === 'o')
                    mutations.putRegionEnd(state)
                if (event.code === 'ArrowRight')
                    mutations.skipSpectrum(state, 5)
                if (event.code === 'ArrowLeft')
                    mutations.skipSpectrum(state, -5)
                if (event.code === 'Delete')
                    mutations.cutRegion(state, {withBlank: true, toBin: true})
                if (event.code === 'Space') {
                    if (!state.my_spectrum.backend.isPaused())
                        state.my_spectrum.pause()
                    else
                        mutations.play(state, {speed: state.play_options.speed, spectrum: state.my_spectrum})
                }
                state.keyboard.action_complete = true;
                return;
            }
            if (state.keyboard.ctrl_key_ready === true && state.keyboard.action_complete === false) {
                if (event.key === 'c')
                    mutations.copyRegion(state)
                else if (event.key === 'v')
                    mutations.pasteRegion(state)
                else if (event.key === 'x')
                    mutations.cutRegion(state, {withBlank: false, toBin: true})
                else if (event.key === 't')
                    mutations.trimRegion(state)
                else if (event.key === 'z')
                    mutations.undoAction(state);
                if (event.key === 'c' || event.key === 'v' || event.key === 'x' || event.key === 'z' || event.key === 't') {
                    state.keyboard.action_complete = true;
                    return;
                }
            }
        }
    }, false);
    document.addEventListener('keyup', (event) => {
        if (event.key === 'Control') {
            state.keyboard.ctrl_key_ready = false;
        }
        if (event.key === 'i' || event.key === 'o' || event.code === "Space" || event.key === 'c' || event.key === 't' || event.key === 'v' || event.key === 'x' || event.key === 'z'
            || event.code === 'ArrowLeft' || event.code === 'ArrowRight' || event.code === 'Delete')
            state.keyboard.action_complete = false;
    }, false);
}
function putMainMarker(state) {

    state.my_spectrum.on('seek', function (seekValue) {
        state.my_spectrum.markers.markers[0].time = (seekValue * state.my_spectrum.getDuration())
        state.my_spectrum.markers._updateMarkerPositions()
    });
}
function eventManager(state) {
    setInterval(() => {
        if (state.my_spectrum != null) {
            updateTimers(state)
            createNewRegion(state)
            if (state.timer.current_time < 0) {
                state.my_spectrum.stop()
            }
            if (state.need_ram_reset) {
                state.need_ram_reset = false;
                resetRam(state)
            }
            //mutations.putNewMarker(state, state.timer.current_time)
            state.bin_is_playing = !document.getElementById('binPlayer').paused;
            updatePeak(state)
        }
    }, 30)
    enterRegion(state)
    scrollZoom(state)
    keyboardEvent(state)
    putMainMarker(state)
}

//PEAK FUNCTIONS

function updatePeak(state) {
    let actual_pos = (100 * (state.timer.current_time / state.timer.total_time)/100)
    if (actual_pos < 0)
        actual_pos = 0
    if (actual_pos > 1)
        actual_pos = 1
    if (state.channel_status.nbrOfChannel > 0 && state.audio_peaks !== null)
        state.vu_meter.height1 = 100 - (getPeakAverage(state.audio_peaks[0], Math.floor(state.audio_peaks[0].length * actual_pos))*100)
    if (state.channel_status.nbrOfChannel > 1 && state.audio_peaks !== null)
        state.vu_meter.height2 = 100 - (getPeakAverage(state.audio_peaks[1], Math.floor(state.audio_peaks[1].length * actual_pos))*100)
}
function getPeakAverage(array, pos) {
    let average = 0;
    if (pos >= 6 && pos <= array.length - 6) {
        for (let i = pos-5; i <= pos+5; i++) {
            average = average + Math.abs(array[i])
        }
        average = average / 10;
        return average;
    }
    else
        return array[pos];
}

//TIMER FUNCTIONS

function updateTimers(state) {
    state.timer.current_time = Math.round(state.my_spectrum.getCurrentTime()*100)/100;
    state.timer_print.current_time = createTimeDisplay(state.timer.current_time)
    state.timer.total_time = Math.round(state.my_spectrum.getDuration() *100)/100
    state.timer_print.total_time = createTimeDisplay(state.timer.total_time)
    let regions = state.my_spectrum.regions.list;
    if(Object.keys(regions).length > 0){
        state.timer.region_start = Math.round(regions[Object.keys(regions)].start*100)/100;
        state.timer_print.region_start = createTimeDisplay(state.timer.region_start)
        state.timer.region_end = Math.round(regions[Object.keys(regions)].end*100)/100;
        state.timer_print.region_end = createTimeDisplay(state.timer.region_end)
        state.timer.region_duration = Math.round((state.timer.region_end-state.timer.region_start)*100)/100;
        state.timer_print.region_duration = createTimeDisplay(state.timer.region_duration)
    }
    else {
        state.timer.region_start = null;
        state.timer.region_end = null;
    }
}
function createTimeDisplay(timeValue) {
    let min = "0:"
    let sec = "0"
    let ms = Math.trunc(((timeValue - (Math.trunc(timeValue))) * 1000)).toString()
    timeValue = Math.trunc(timeValue)
    if (timeValue >= 60) {
        min = Math.trunc((timeValue/60)).toString() + ":"
    }
    if (timeValue%60 < 10)
        sec = sec + (timeValue%60).toString() + ":";
    else
        sec = (timeValue%60).toString() + ":";
    while (ms.length !== 3)
        ms = "0" + ms
    if (min.length < 3)
        min = "0" + min
    return (min + sec + ms)
}

//REGION FUNCTIONS

function createNewRegion(state) {
    state.my_spectrum.on('region-created', function(region){
        let regions = region.wavesurfer.regions.list;
        if(Object.keys(regions).length > 0){
            regions[Object.keys(regions)[0]].remove();
        }
    });
    state.my_spectrum.on('region-update-end', function () {
        document.getElementById("selection_text").click();
    })
}
function enterRegion(state) {
    state.my_spectrum.on('region-in', function () {
        if (state.play_options.dodge_region === true)
            mutations.cursorToTimer(state, state.timer.region_end)
    });
}

// OTHER

function resetRam(state) {
    mutations.zoom(state, 1)
    mutations.zoom(state, -1)
}
function generateAudioBackend() {
    if (state.my_spectrum.backend.buffer.numberOfChannels === 1) {
        state.my_spectrum.ActiveChannels = [1];
        state.my_spectrum.drawer.params.ActiveChannels = state.my_spectrum.ActiveChannels;
        state.my_spectrum.SelectedChannelsLen = 1;
    } else if (state.my_spectrum.backend.buffer.numberOfChannels === 2) {
        state.my_spectrum.ActiveChannels = [1, 1];
        state.my_spectrum.drawer.params.ActiveChannels = state.my_spectrum.ActiveChannels;
        state.my_spectrum.SelectedChannelsLen = 2;
    }
}