<template>
    <div class="">
        <Loading v-if="loading"/>
        <div v-else>

            <div class="mx-auto position-absolute" style="z-index:5;right:0;left:0;width: 900px">
                <b-alert v-if="login.demo" variant="info" dismissible show>
                    {{$t('login.error-license-demo')}}
                </b-alert>
                <b-alert v-if="login.dtEnd" variant="warning" dismissible show>
                    {{login.dtEnd}}
                </b-alert>
            </div>
            <b-card class="mx-auto bg-light mt-4 p-3" style="max-width: 450px">
                <div class="d-flex justify-content-between">
                    <div>{{version}}</div>
                    <b-form-select class="w-auto" v-model="$i18n.locale" :options="$i18n.availableLocales"
                                   @change="changeLocale">
                        <!--<option v-for="(lang) in $i18n.availableLocales" :key="lang" :value="lang">{{ lang }}</option>-->
                    </b-form-select>
                    <!--                    <b-form-select class="w-auto" v-model="$i18n.locale">
                                            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                                        </b-form-select>-->
                </div>
                <div class="text-center mb-3">
                    <img alt="ines logo" src="@/assets/ines.png">
                </div>
                <b-form @submit="onSubmit">
                    <b-input-group class="mb-3 justify-content-center">
                        <template v-slot:prepend>
                            <b-input-group-text class="justify-content-center" style="width: 150px">
                                {{$t('login.login')}}
                            </b-input-group-text>
                        </template>
                        <b-input class="" v-bind:placeholder="$t('login.login')" autofocus
                                 v-model="authentication.uname" autocomplete="off"/>
                    </b-input-group>
                    <div class="auth-part mb-2  justify-content-center" v-if="login.show">
                        <b-input-group class="mb-3 justify-content-center">
                            <template v-slot:prepend>
                                <b-input-group-text class="justify-content-center" style="width: 150px">
                                    {{$t('login.station')}}
                                </b-input-group-text>
                            </template>
                            <b-form-select class="" v-model="authentication.fk_tb_brand"
                                           :options="login.option">

                            </b-form-select>
                        </b-input-group>
                        <b-input-group class="mb-3 justify-content-center">
                            <template v-slot:prepend>
                                <b-input-group-text class="justify-content-center" style="width: 150px">
                                    {{$t('login.password')}}
                                </b-input-group-text>
                            </template>
                            <b-input class="" v-bind:placeholder="$t('login.password')" type="password"
                                     v-model="authentication.upass"/>
                        </b-input-group>
                        <div class="text-center  mb-3 reset-password-login " v-if="login.showResetPassword">
                            <em style="color: red">{{$t('login.reset_password_msg')}}</em>
                        </div>
                        <b-form-checkbox
                                name="eula-login"
                                v-model="authentication.eula_accepted"
                        >
                            <i18n path="login.accept_eula">
                                <a href="CLUF-ACE.pdf">{{ $t('login.eula') }}</a>
                            </i18n>
                        </b-form-checkbox>
                    </div>

                    <div class="text-center">
                        <input class="d-none">
                        <b-button variant="success" type="submit">{{$t('login.sign_in')}}
                            <b-icon icon="unlock"/>
                        </b-button>
                    </div>
                </b-form>

            </b-card>
            <!--    <template v-slot:modal-footer>
                  {{version}}
                </template>-->
        </div>
    </div>

</template>

<script>
    //import MixinConf from "@/main";
    import {ConfJson, version} from "@/main"
    import Loading from "@/components/Loading"
    //import router from "@/router";

    export default {
        //mixins:[MixinConf],
        name: "Login",
        metaInfo: {
            title: 'Login',
            titleTemplate: '%s - Ines',
        },
        created: function () {
            let MyComponent = this;
            localStorage.removeItem("token")
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.loading = false;
                    clearInterval(myInterval)
                }
            }, 100);
        },
        data: function () {
            return {
                title: "Se connecter",
                version: version,
                loading: true
            }

        },
        computed: {
            login() {
                return this.$store.state.login
            },
            authentication() {
                return this.$store.state.login.authentication
            }
        },
        props: {
            //version: "test"
        },
        components: {Loading},
        methods: {
            onSubmit: function (evt) {
                evt.preventDefault();
                if (!this.login.show) {
                    this.$store.commit('login/getBrandForConnect')
                } else {
                    this.$store.commit('login/authentication')
                }
            },
            changeLocale: function () {
                localStorage.setItem('locale', this.$i18n.locale)
            }
        }
    }
</script>

<style scoped>

</style>

<!--
<i18n>
    {
    "en": {
    "hello": "Hello i18n in SFC2!"
    },
    "fr": {

    }
    }
</i18n>-->
