import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Rtc from '../views/Rtc.vue'
import Standard from '../views/Standard.vue'
import Login from '../views/Login.vue'
import Solo from '../views/Solo.vue'
import Users from '../views/Admin.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    //meta: {title: 'RTC-Manager'},
  },
  {
    path: '/rtc',
    name: 'RTC',
    component: Rtc,
    //meta: {title: 'RTC-Manager'},
  },  {
    path: '/standard',
    name: 'Standard',
    component: Standard,
    //meta: {title: 'Ines S'},
  },
  {
    path: '/login',
    name: 'Login',
    //meta: {title: 'Login'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path: '/solo',
    name: 'Solo',
    //meta: {title: 'Solo'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Solo
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path: '/admin',
    name: 'Admin',
    //meta: {title: 'Solo'},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Users
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  //mode:'history',
  routes
})


export default router
