<template>
    <div class="home">
        <Loading v-if="loading"/>

        <perfect-scrollbar v-else class="vh-100">
            <div class="vh-100  p-1" style="min-height: 500px">
                <b-card no-body class="bg-light h-100 col-3">
                    <b-card-body class="p-2">
                        <div class=" mb-1">
                            <div class="d-flex">
                                <img alt="ines-rtc logo" src="@/assets/ines-rtc.png">
                                <div class="d-flex w-100 align-self-center justify-content-end">
                                    <b-button variant="secondary" class="d-flex p-2" v-b-modal.modal-config>
                                        <b-icon icon="gear" font-scale="1.5" class="align-self-center"/>
                                    </b-button>
                                    <modal-config title="Line Modal" />
                                    <b-button variant="danger" class="d-flex ml-2 p-2" v-on:click="delogMe()">
                                        <b-icon icon="box-arrow-right" font-scale="1.5" class="align-self-center"/>
                                    </b-button>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    {{version}}
                                </div>
                                <div>
                                    {{accountName}}
                                </div>
                            </div>
                        </div>
                        <b-form @submit="newKey" class="mb-2">
                            <b-input-group class="mb-1 justify-content-center">
                                <b-input-group-prepend is-text class="mid-size ">
                                    <b-icon icon="envelope" class="mx-auto"/>
                                </b-input-group-prepend>
                                <b-input-group-prepend is-text class="mid-size">
                                    <b-icon icon="phone" class="mx-auto"/>
                                </b-input-group-prepend>
                                <b-input class="" v-bind:placeholder="$t('rtc.recipient')"
                                         v-model="new_key.dest" autocomplete="off"/>
                            </b-input-group>
                            <b-input-group class="mb-1 justify-content-center">
                                <b-input-group-prepend is-text class="full-size">
                                    <b-icon icon="person" class="mx-auto"/>
                                </b-input-group-prepend>
                                <b-input class="" v-bind:placeholder="$t('rtc.name')" v-model="new_key.name" autocomplete="off"/>
                            </b-input-group>
                            <div class="d-flex">
                                <b-input-group class="mb-1 justify-content-center " style="width: 250px">
                                    <b-input-group-prepend is-text class="full-size">
                                        <b-icon icon="calendar3" class="mx-auto"/>
                                    </b-input-group-prepend>
                                    <b-input class="" v-bind:placeholder="$t('rtc.year')" v-model.number="new_key.year"
                                             type="number" min="1900" max="2100" autocomplete="off"/>
                                </b-input-group>

                                <b-form-radio-group
                                        v-model="new_key.video"
                                        buttons
                                        class="mb-1 ml-1 w-50"
                                >
                                    <b-form-radio value="false" class="">
                                        <b-icon icon="mic" class=""/>
                                    </b-form-radio>
                                    <b-form-radio value="true" class="">
                                        <b-icon icon="camera-video" class=""/>
                                    </b-form-radio>
                                </b-form-radio-group>

                            </div>

                            <b-input-group class="mb-1 justify-content-center">
                                <b-input-group-prepend is-text class="full-size">
                                    <b-icon icon="stopwatch" class="mx-auto"/>
                                </b-input-group-prepend>
                                <b-input class="" v-bind:placeholder="$t('rtc.time')"
                                         v-model="key.time_unit_nb" autocomplete="off"/>
                                <b-form-select v-model="key.time_unit_value" :options="time_unit"/>
                            </b-input-group>
                            <div class=" text-center">
                                <input id="fk-tb-user-login" class="d-none">
                                <b-button variant="primary" type="submit">{{$t('rtc.send')}}
                                    <b-spinner small v-if="key.send"/>
                                    <b-icon icon="check2-circle" v-else/>

                                </b-button>
                            </div>
                        </b-form>

                        <perfect-scrollbar class="key-list list-group bg-white ">
                            <drag v-for="(key, index) in all_keys" :key="index"
                                  class="drag list-group-item rounded d-flex justify-content-between"
                                  v-bind:class="{ 'list-group-item-info': key.Status === 'INCOMING_CALL',
                              'list-group-item-warning': key.Status === 'RESET','list-group-item-success': key.Status === 'ON_CALL' || key.Status === 'NEW_INCOMMING_SRC' , }"
                            ><span>{{key.RedisKey.Name + " - "+  key.RedisKey.Year + " - " + key.RedisKey.Key}}</span>
                                <b-button variant="outline-danger"
                                          v-on:click="deleteKey(key.RedisKey.Name,key.RedisKey.Year,key.RedisKey.Key)">
                                    <b-icon icon="trash"/>
                                </b-button>
                            </drag>
                        </perfect-scrollbar>


                    </b-card-body>
                </b-card>
                <!--    <template v-slot:modal-footer>
                      {{version}}
                    </template>-->


                <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
            </div>
        </perfect-scrollbar>
        <modal-button/>
        <div v-for="(line,index) in lines.all_lines" :key="index" >

            <my-line :line="line"/>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    /*
    *  IMPORT COMPONENTS
    * */
    import Loading from "@/components/Loading"
    import ModalConfig from "@/components/rtc/ModalConfig";
    import ModalButton from "@/components/rtc/ModalButton";
    import MyLine from "@/components/rtc/MyLine";

    /*
    *  IMPORT LIBS
    * */
    import {ConfJson, version} from "@/main";
    import {Drag} from "vue-easy-dnd";
    import {PerfectScrollbar} from 'vue2-perfect-scrollbar'


    export default {
        name: 'Rtc',
        metaInfo: {
            title: 'RTC',
            titleTemplate: '%s - Ines',
        },
        created: function () {
            //console.log(ConfJson)
            let MyComponent = this;//TODO : GET LINE (current state (to get the streams) and button ) !
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.$store.commit('licence/checkLicense', "rtc");
                    MyComponent.loading = false;
                    MyComponent.$store.commit('key/getCallerList');
                    MyComponent.$store.commit('lines/getAllLines');
                    //MyComponent.$store.commit('key/getUpdatedKeys');
                    MyComponent.$store.dispatch('event_lines/getEventLines');
                    clearInterval(myInterval);
                }
            }, 100);

        },
        components: {
            MyLine,
            Loading,
            ModalConfig,
            ModalButton,
            Drag,
            PerfectScrollbar,

        },
        data: function () {
            return {
                version: version,
                accountName: localStorage.getItem("accountName"),
                loading: true,
                time_unit: [{"value": 60, "text": this.$t('rtc.minutes')}, {
                    "value": 3600,
                    "text": this.$t('rtc.hours')
                }, {"value": 86400, "text": this.$t('rtc.days')}],
                //numbers: [1, 2, 3, 4, 5],
            }
        },
        computed: {
            key() {
                return this.$store.state.key
            }, new_key() {
                return this.$store.state.key.new_key
            }, all_keys() {
                return this.$store.state.key.all_keys
            }, lines() {
                return this.$store.state.lines
            },
        },
        methods: {
            newKey:function (evt) {
                evt.preventDefault();
                this.$store.commit('key/newKey')
            },
            deleteKey:function (name, year, key) {
                this.$store.commit('key/deleteKey', {"name": name, "year": year, "key": key})
            },
            delogMe:function () {
                this.$store.commit('login/delogMe')
            }
        }
    }
</script>

<style scoped>
    .minH500 {
        min-height: 500px;
        height: 100vh;
        overflow-y: scroll;
    }

    .mid-size {
        width: 50px;
    }

    .mid-size > .input-group-text {
        width: 50px;
    }

    .full-size {
        width: 100px;
    }

    .full-size > .input-group-text {
        width: 100px;
    }

    .key-list {
        height: calc(100% - 320px);

        overflow-y: scroll;
    }

    .drag {
        width: 100%;

        display: inline-flex;
        align-items: center;
        transition: all 0.5s;
    }
</style>