import $ from 'jquery'
import {ConfJson} from "@/main"
import router from "@/router";
import i18n from '@/i18n';

let UserApiPath = "/users_api";//"/"//"/users_api"

const state = {
    show: false,
    showResetPassword: false,
    dtEnd: null,
    demo: false,
    option: [],
    authentication: {
        "uname": '',
        "fk_tb_user": 0,
        "fk_tb_brand": 0,
        "upass": '',
        "eula_accepted": false,
    }
}

const mutations = {
    getBrandForConnect(state) {
        //state.show = true
        //console.log(state.authentication.uname)
        $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "get_brand_for_connect",
            "brand": localStorage.getItem("BrandName"),
            "uname": state.authentication.uname
        }).done(function (data) {
            console.log(data)
            if (data.brand_for_connect === null || data.brand_for_connect.length === 0) {
                alert( i18n.t('login.error-no-user'));
                return;
            }
            //state.dtEnd = data.demo
            if (data.dt_end.length > 0 && data.dt_end !== "no") {
                let currentDate = new Date()
                let dtEnd = stringToDate(data.dt_end)
                if (dtEnd < currentDate.addDays(7)) {
                    state.dtEnd =  i18n.t('login.error-license-dt-end') + " (" + data.dt_end + ").";
                }
            }
            state.demo = data.demo
            if (parseInt(data.brand_for_connect[0].fk_tb_user) === -1) {
                if (window.confirm('Aucune marque disponible dans la base de donnée, cliquer sur Ok pour lancer start')) {
                    window.location.href = ConfJson.ines_rtc_ws.service_url + '/start';
                }
                return
            }
            if (parseInt(data.brand_for_connect[0].fk_tb_user) === -2) {
                alert(i18n.t('login.already_logged'))
                return
            }
            state.showResetPassword = data.brand_for_connect[0].reset_password
            state.authentication.fk_tb_brand = data.brand_for_connect[0].fk_tb_brand[0];
            state.authentication.fk_tb_user = data.brand_for_connect[0].fk_tb_user;
            state.authentication.eula_accepted = data.brand_for_connect[0].eula_accepted;
            for (let i = 0; i < data.brand_for_connect[0].fk_tb_brand.length; i++) {
                state.option.push({
                    value: data.brand_for_connect[0].fk_tb_brand[i],
                    text: data.brand_for_connect[0].brands_label[i]
                })
            }
            state.show = true
        }).fail(function (e) {
            console.log(e)
            if (e.status === 401) {
                alert( i18n.t('login.error-no-license'));
            } else if (e.status === 415) {
                alert( i18n.t('login.error-no-db'));
            } else {
                alert( i18n.t('login.error-no-srv'));
            }

            console.log(e)
        }).always(function () {
        });
        //console.log(obj)

        //state.authentication = obj
        //alert(state.authentication.uname)
    }, authentication() {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "authentication",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify(state.authentication)
        }).done(function (data) {
            data = JSON.parse(data);
            if (data && data.status === 0) {
                localStorage.setItem("accountName", state.authentication.uname);
                localStorage.setItem("token", data.token);
                localStorage.setItem("license", JSON.stringify(data.license));
                console.log(state.option)
                console.log(state.authentication.fk_tb_brand)
                for (let i = 0; i < state.option.length; i++) {
                    if (state.option[i].value === state.authentication.fk_tb_brand) {
                        localStorage.setItem("BrandName", state.option[i].text);
                        break
                    }
                }


                let route = localStorage.getItem('route')
                if (route !== undefined && route !== null && route.length > 0) {
                    router.push(route)
                } else {
                    router.push(getRoute('/'))
/*                    let lic = data.licence
                    for (let i = 0; i < lic.length; i++) {
                        if (lic[i].Label === "nb_line" && lic[i].Value > 0) {
                            router.push('/')
                        }
                        if (lic[i].Label === "nb_solo" && lic[i].Value > 0) {
                            router.push('/solo')
                        }
                    }*/
                    //TODO : switch case en fonction des licences

                }

            } else {
                let msg = "";
                if (data.status < 0)
                    msg = i18n.t('login.error' + data.status);
                else
                    msg = data
                alert(i18n.t('login.error') + msg)
            }

        }).fail(function (e) {
            console.log(e)
            if (e.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('last_call');
                router.go()
            } else {
                console.log("Error " + e.status + " {" + e.bodyText + "}")
            }
        });
    }, delogMe() {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "delog",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token")
        }).done(function () {

        }).fail(function () {
            //alert("KO")
        }).always(function () {
            localStorage.removeItem('token')
            localStorage.removeItem('last_call');
            router.go()
        });
    }

}

function stringToDate(s) {

    if (s.split('-').length === 3) {
        return new Date(s.split('-')[0], parseInt(s.split('-')[1] - 1), s.split('-')[2],23,59,59)
    } else if (s.split('/').length === 3) {
        return new Date(s.split('/')[2], parseInt(s.split('/')[1] - 1), s.split('/')[0],23,59,59)
    }
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default {
    namespaced: true,
    state,
    mutations
}

function getRoute(currentRoute) {
    //FUNCTION AUSSI PRESENTE DANS LE MAIN.JS ! // TODO : A RELIER OU UPDATE !
    let lic = getRouteFormLicense()
    if ((currentRoute === "/solo" && lic.Solo) ||
        (currentRoute === "/speaker" && lic.Speaker) ||
        (currentRoute === "/admin" && lic.Admin) ||
        (currentRoute === "/screen" && lic.Screen) ||
        (currentRoute === "/games" && lic.Games) ||
        (currentRoute === "/admin" && lic.Admin) ||
        (currentRoute === "/standard" && lic.Standard)
    ) {
        return currentRoute
    } else {
        return lic.DefaultRoute;
    }
}

function getRouteFormLicense() {
    //TODO: FAIRE LES PAGES CORRECTEMENT !
    let ret ={Home:false, Admin:false,Rtc:false, Solo:false, Standard: false,Screen: false, Speaker:false,  Games:false, DefaultRoute : "/" }
    let lic = JSON.parse(localStorage.getItem("license"));
    for (let i = 0; i < lic.length; i++) {
        if (lic[i].Label === "nb_line" && lic[i].Value > 0) {
            ret.DefaultRoute = "/rtc";
            ret.Rtc = true
            return ret
        }
        if (lic[i].Label === "nb_standard" && lic[i].Value > 0) {
            ret.DefaultRoute = "/standard";
            ret.Standard = true
            return ret
        }
        if (lic[i].Label === "nb_solo" && lic[i].Value > 0) {
            ret.DefaultRoute = "/solo";
            ret.Solo = true
            return ret
        }
        if (lic[i].Label === "nb_screen" && lic[i].Value > 0) {
            ret.DefaultRoute = "/screen";
            ret.Screen = true
            return ret
        }
        if (lic[i].Label === "nb_speaker" && lic[i].Value > 0) {
            ret.DefaultRoute = "/speaker";
            ret.Speaker = true
            return ret
        }
        if (lic[i].Label === "nb_games" && lic[i].Value > 0) {
            ret.DefaultRoute = "/games";
            ret.Games = true
            return ret
        }
    }
    return ret
}