<template>
  <div class="d-flex justify-content-between p-1">
    <div>
      <b-btn variant="outline-dark" @click="zoom(1)" @focus="blurMe" class="mx-1 button-dimension" v-b-tooltip.hover
             :title="$t('solo_editor.solo_editor.button_desc.zoom_in')">
        <b-icon font-scale="1.5" icon="zoom-in" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-dark" @click="zoom(-1)" @focus="blurMe" class="mx-1 button-dimension" v-b-tooltip.hover
             :title="$t('button_desc.zoom_out')">
        <b-icon font-scale="1.5" icon="zoom-out" aria-hidden="true"></b-icon>
      </b-btn>
    </div>
    <div class="d-flex">

      <b-btn variant="outline-success" @click="play(-IA.play_options.speed, IA.my_spectrum)" @focus="blurMe"
             class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.play_back')">
        <b-icon font-scale="1.8" icon="caret-left-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-form-select v-model="IA.play_options.speed" :options="speed_options" class="h-100"/>
      <b-btn v-if="IA.my_spectrum && !IA.my_spectrum.backend.isPaused()" variant="outline-success" @click="IA.my_spectrum.pause()" @focus="blurMe"
             class="mx-1 button-dimension" v-b-tooltip.hover :title="$t('solo_editor.button_desc.pause')">
        <b-icon font-scale="1.8" icon="pause-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn v-else @click="play(IA.play_options.speed, IA.my_spectrum)"
             variant="outline-success" @focus="blurMe"
             class="mx-1 button-dimension" v-b-tooltip.hover :title="$t('solo_editor.button_desc.play')" not-focusable>
        <b-icon font-scale="1.8" icon="caret-right-fill" aria-hidden="true"></b-icon>
      </b-btn>

      <b-btn variant="outline-danger" @click="stop()" @focus="blurMe" class="mx-1 button-dimension" v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.stop')">
        <b-icon font-scale="1.6" icon="stop-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-success" @click="playRegion()" @focus="blurMe" class="mx-1 button-dimension p-1"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.play_selection')">
        <svg  style="height: 38px; width: 38px" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="" viewBox="0 0 16 16">
          <path
              d="M 10.97583,8.5944435 6.2038143,11.466007 C 5.7988342,11.709452 5.2791097,11.414673 5.2791097,10.923894 V 5.1807678 c 0,-0.4900013 0.5189745,-0.7855576 0.9247046,-0.5413348 L 10.97583,7.5109962 a 0.60147049,0.62377943 0 0 1 0,1.0834473 z"/>
          <rect width="0.60000002" height="13" x="12.5" y="1.5"/>
          <rect width="0.60000002" height="13" x="2.9000001" y="1.5"/>
          <rect width="0.43841189" height="2.6202836" x="13.260409" y="-7.4073777" ry="0.146236"
                transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="14.758483" y="-5.6496544" ry="0.146236"
                transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="16.141792" y="-3.5977314" ry="0.146236"
                transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="19.016073" y="0.6980378" ry="0.146236" transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="8.6238327" y="8.4559097" ry="0.146236" transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="7.1831393" y="6.5510859" ry="0.146236" transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="5.5953088" y="4.3216043" ry="0.146236" transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="2.6869624" y="0.12988128" ry="0.146236"
                transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="17.593279" y="-1.4866219" ry="0.146236"
                transform="rotate(35)"/>
          <rect width="0.43841189" height="2.6202836" x="4.104342" y="2.0777586" ry="0.146236" transform="rotate(35)"/>
        </svg>
      </b-btn>
      <b-btn variant="outline-success" @click="playWithoutRegion()" @focus="blurMe" class="mx-1 p-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.play_without_select')">
        <svg style="height: 40px; width: 40px" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
             class="bi bi-play-fill" viewBox="0 0 16 16">
          <path
              d="M 3.4483604,8.3096084 1.0623523,9.9502678 C 0.85986226,10.089359 0.6,9.9209362 0.6,9.6405328 V 6.3592154 c 0,-0.2799607 0.25948726,-0.4488259 0.4623523,-0.30929 l 2.3860081,1.6406589 a 0.30073528,0.35639445 0 0 1 0,0.6190241 z"/>
          <path
              d="M 15.24836,8.3096084 12.862352,9.9502682 C 12.659862,10.089359 12.4,9.9209382 12.4,9.6405332 V 6.3592154 c 0,-0.2799607 0.259487,-0.4488259 0.462352,-0.30929 l 2.386008,1.6406589 a 0.30073528,0.35639445 0 0 1 0,0.6190241 z"/>
          <rect width="0.60000002" height="13" x="11.327085" y="1.5"/>
          <rect width="0.60000002" height="13" x="4.1918764" y="1.5"/>
          <rect width="0.63970476" height="3.3927476" x="16.789389" y="6.2506251" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="14.6838" y="3.5513489" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="12.743975" y="1.4325393" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="10.704289" y="-1.1523526" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="8.7967253" y="-3.4154742" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="6.6392207" y="-2.1370523" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="8.7119093" y="0.52053201" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="10.636559" y="2.9905033" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="12.708931" y="5.5550857" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
          <rect width="0.63970476" height="3.3927476" x="14.697891" y="7.6744318" ry="0.18934661"
                transform="matrix(0.89900643,0.43793543,-0.70938763,0.70481856,0,0)"/>
        </svg>
      </b-btn>
      <b-btn variant="outline-success" @click="cursorToRegionStart(0)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.to_selection')">
        <svg style="height: 35px; width: 35px" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
          <path
              d="M 3.2398937,4.002406 2.2017644,2.696516 C 2.113754,2.5856908 2.2203238,2.4434652 2.3977496,2.4434652 h 2.0762581 c 0.1771449,0 0.283995,0.1420204 0.1957036,0.2530508 l -1.0381291,1.30589 a 0.22550907,0.16459592 0 0 1 -0.3916885,0 z"/>
          <rect width="0.72796524" height="10.548395" x="11.819724" y="4.4990106"/>
          <rect width="0.72796524" height="10.548395" x="3.1627548" y="4.4990106"/>
          <rect width="0.7338478" height="3.5059485" x="21.38863" y="11.197931" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="18.973171" y="8.4085941" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="16.747871" y="6.2190881" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="14.408012" y="3.5479498" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="12.219719" y="1.2093173" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="9.7447023" y="2.5303948" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="12.122422" y="5.2766514" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="14.330316" y="7.8290348" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="16.70767" y="10.479185" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="0.7338478" height="3.5059485" x="18.989338" y="12.669244" ry="0.19566427"
                transform="matrix(0.95081446,0.30976099,-0.83289259,0.55343468,0,0)"/>
          <rect width="3.1968467" height="0.65688634" x="0.65688634" y="0.74447101"/>
          <rect width="1.1167067" height="0.65688634" x="1.4013574" y="-3.8537333" transform="rotate(90)"/>
        </svg>
      </b-btn>
      <b-btn variant="outline-success" @click="skipSpectrum(-5)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.skip_back')">
        <b-icon font-scale="1.6" icon="skip-backward-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-success" @click="skipSpectrum(5)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.skip_forward')">
        <b-icon font-scale="1.6" icon="skip-forward-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-success" @click="skipSpectrum(-10000000)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.to_start')">
        <b-icon font-scale="1.6" icon="skip-start-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-success" @click="skipSpectrum(10000000)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.to_end')">
        <b-icon font-scale="1.6" icon="skip-end-fill" aria-hidden="true"></b-icon>
      </b-btn>

    </div>
    <div>
      <b-btn variant="outline-primary" @click="putRegionStart()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.in')">
        IN
      </b-btn>
      <b-btn variant="outline-primary" @click="putRegionEnd()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.out')">
        OUT
      </b-btn>
      <b-btn variant="outline-primary" @click="copyRegion()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.copy')">
        <b-icon font-scale="1.5" icon="files" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-primary" @click="cutRegions(false, true)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.cut')">
        <b-icon font-scale="1.5" icon="scissors" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-primary" @click="cutRegions(true, true)" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.erase')">
        <svg style="height: 28px; width: 28px" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
          <path
              d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
        </svg>
      </b-btn>
      <b-btn variant="outline-primary" @click="pasteRegion()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.paste')">
        <b-icon font-scale="1.5" icon="clipboard" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-primary" @click="trimRegions()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.trim')">
        <svg style="height: 32px; width: 32px" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
          <rect width="0.71534926" height="10.942133" x="11.239499" y="2.3316789"/>
          <rect width="0.78103787" height="10.75161" x="3.9072256" y="2.522202"/>
          <g transform="matrix(0.27431079,0,0,0.47404163,-0.33330829,4.2237998)">
            <path
                d="M 3.5,3.5 C 2.886,2.616 3.426,1.538 4.358,1 L 8,7.226 11.642,1 c 0.932,0.538 1.472,1.616 0.858,2.5 l -3.69,5.11 1.556,2.661 A 2.5,2.5 0 1 1 9.572,11.908 L 8,9.73 6.428,11.907 A 2.5,2.5 0 1 1 5.634,11.27 L 7.19,8.61 Z m 2.5,10 a 1.5,1.5 0 1 0 -3,0 1.5,1.5 0 0 0 3,0 z m 7,0 a 1.5,1.5 0 1 0 -3,0 1.5,1.5 0 0 0 3,0 z"/>
          </g>
          <g transform="matrix(0.27431079,0,0,0.47417683,11.906674,4.2964359)">
            <path
                d="M 3.5,3.5 C 2.886,2.616 3.426,1.538 4.358,1 L 8,7.226 11.642,1 c 0.932,0.538 1.472,1.616 0.858,2.5 l -3.69,5.11 1.556,2.661 A 2.5,2.5 0 1 1 9.572,11.908 L 8,9.73 6.428,11.907 A 2.5,2.5 0 1 1 5.634,11.27 L 7.19,8.61 Z m 2.5,10 a 1.5,1.5 0 1 0 -3,0 1.5,1.5 0 0 0 3,0 z m 7,0 a 1.5,1.5 0 1 0 -3,0 1.5,1.5 0 0 0 3,0 z"/>
          </g>
          <g transform="matrix(0.51382647,0,0,0.54766224,4.0066819,3.7386639)">
            <path
                d="m 10.97,4.97 a 0.75,0.75 0 0 1 1.07,1.05 L 8.05,11.01 A 0.75,0.75 0 0 1 6.97,11.03 L 4.324,8.384 a 0.75,0.75 0 1 1 1.06,-1.06 l 2.094,2.093 3.473,-4.425 a 0.267,0.267 0 0 1 0.02,-0.022 z"/>
          </g>
        </svg>
      </b-btn>
    </div>
    <div>
      <b-btn variant="outline-info" @click="downloadAudio()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.download')">
        <b-icon font-scale="1.5" icon="download" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-dark" @click="undoAction()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.undo')">
        <b-icon font-scale="1.5" style="transform: rotateY(180deg)" icon="reply-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-dark" @click="redoAction()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover :title="$t('solo_editor.button_desc.redo')">
        <b-icon font-scale="1.5" icon="reply-fill" aria-hidden="true"></b-icon>
      </b-btn>
      <b-btn variant="outline-danger" @click="stopEdit()" @focus="blurMe" class="mx-1 button-dimension"
             v-b-tooltip.hover
             :title="$t('solo_editor.button_desc.stop_edit')">
        <b-icon font-scale="1.5" icon="power" aria-hidden="true"></b-icon>
      </b-btn>
    </div>
  </div>
</template>

<script>

//import i18n from '@/i18n';

export default {
  name: 'ButtonPannel',
  data() {
    return {
      speed_options: [
        {value: 0.25, text: 'x0.25 ' + this.$t('solo_editor.speed')},
        {value: 0.5, text: 'x0.5 ' + this.$t('solo_editor.speed')},
        {value: 1, text: 'x1 ' + this.$t('solo_editor.speed')},
        {value: 2, text: 'x2 ' + this.$t('solo_editor.speed')},
        {value: 3, text: 'x3 ' +this.$t('solo_editor.speed')},
        {value: 4, text: 'x4 ' + this.$t('solo_editor.speed')},
      ]
    }
  },
  created: function () {
    /*$( document.body ).click(function() {
        $("button").blur()
    });*/
  },
  components: {},
  methods: {
    play(speed, spectrum) {
      this.$store.commit('inesAudio/play', {speed: speed, spectrum: spectrum})
    },
    blurMe(e) {
      e.currentTarget.blur()
    },
    stop() {

      this.$store.commit('inesAudio/stop')
    },
    zoom(pxPerSec) {
      this.$store.commit('inesAudio/zoom', pxPerSec)
    },
    skipSpectrum(seconds) {
      this.$store.commit('inesAudio/skipSpectrum', seconds)
    },
    toNextMark() {
      this.$store.commit('inesAudio/toNextMark')
    },
    clearRegions() {
      this.$store.commit('inesAudio/clearAllRegions')
    },
    setChannelsVolume(volumes) {
      this.$store.commit('inesAudio/setChannelsVolume', volumes)
    },
    putNewMarker(timer) {
      this.$store.commit('inesAudio/putNewMarker', timer)
    },
    cutRegions(withBlank, toBin) {
      this.$store.commit('inesAudio/cutRegion', {withBlank: withBlank, toBin: toBin})
    },
    trimRegions() {
      this.$store.commit('inesAudio/trimRegion')
    },
    cursorToRegionStart(seconds_delay) {
      this.$store.commit('inesAudio/cursorToRegionStart', seconds_delay)
    },
    playRegion() {
      this.$store.commit('inesAudio/playRegion')
    },
    playWithoutRegion() {
      this.$store.commit('inesAudio/playWithoutRegion')
    },
    copyRegion() {
      this.$store.commit('inesAudio/copyRegion')
    },
    pasteRegion() {
      this.$store.commit('inesAudio/pasteRegion')
    },
    downloadAudio() {
      this.$store.commit('inesAudio/downloadAudio')
    },
    undoAction() {
      this.$store.commit('inesAudio/undoAction')
    },
    redoAction() {
      this.$store.commit('inesAudio/redoAction')
    },
    putRegionStart() {
      this.$store.commit('inesAudio/putRegionStart')
    },
    putRegionEnd() {
      this.$store.commit('inesAudio/putRegionEnd')
    },
    stopEdit() {
      this.$store.commit('inesAudio/stopEdit')
    }
  },
  props: [
    "label", "id"
  ]
  , computed: {
    IA() {
      return this.$store.state.inesAudio
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.button-dimension {
  width: 56px;
  height: 49px;
}

.icon-width {
  width: 35px;
}

</style>
