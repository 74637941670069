<template>
  <b-card no-body>
    <b-card-header class="p-0 ">
      <div class="clip-header p-1">
        <div class="d-flex justify-content-between h-100 ">
          <div class="clipboard-title" :style="IA.bin_is_hide ? 'font-size:15pt;' : ''">
            {{ $t('solo_editor.clipboard.title') }}
          </div>
          <div>
            <b-btn @focus="blurMe" class="clip-hide-button "  @click="hideOrRevealBin" variant="outline-secondary"
                   v-b-tooltip.hover
                   title="Reduce/Expand Clipboard">
              <b-icon v-if="!IA.bin_is_hide" icon="arrow-bar-right" aria-hidden="true"></b-icon>
              <b-icon v-else icon="arrow-bar-left" aria-hidden="true"></b-icon>
            </b-btn>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-text>
      <div class="d-flex element-list-h">
        <div  class="w-0" id="bin-spectrum"></div>
        <b-list-group class="overflow-auto w-100" flush>
          <BinElement v-for="(_, i) in IA.bin.data" :index="i" v-bind:key="i"/>
        </b-list-group>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import BinElement from "./BinElement";

export default {
  name: 'Clipboard',
  components: {BinElement},
  methods: {
    blurMe(e) {
      e.currentTarget.blur()
    },
    hideOrRevealBin() {
      this.$store.commit('inesAudio/hideOrRevealBin')
    }
  },
  mounted() {
  },
  props: {
    msg: String
  },
  computed: {
    IA() {
      return this.$store.state.inesAudio
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.clipboard-title {
  font-size: 22pt;
  padding-top: 10px;
}

.clip-hide-button {
  width: 50px;
  height: 100%;

}

.clip-header {
  height: 63px;
  position: relative;
}

.element-list-h {
  height: 230px;
}

</style>
