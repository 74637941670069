export default class AudioEditor {
    constructor (wavesurfer) {
        this.wavesurfer = wavesurfer;
    }

    paste(params, callback){
        let offlineAudioContext = this.wavesurfer.backend.ac
        let originalAudioBuffer = this.wavesurfer.backend.buffer;

        let cursorPosition = this.wavesurfer.getCurrentTime()
        let newAudioBuffer = offlineAudioContext.createBuffer(
            originalAudioBuffer.numberOfChannels,
            originalAudioBuffer.length + params.cutSelection.length,
            originalAudioBuffer.sampleRate);

        for (let channel = 0; channel < originalAudioBuffer.numberOfChannels;channel++) {
            let new_channel_data = newAudioBuffer.getChannelData(channel);
            let empty_segment_data = params.cutSelection.getChannelData(channel);
            let original_channel_data = originalAudioBuffer.getChannelData(channel);

            if ((params.channelStatus.left === false && channel === 0) ||
                (params.channelStatus.right === false && channel === 1)) {
                new_channel_data.set(original_channel_data)
            }
            else {
                let before_data = original_channel_data.subarray(0, cursorPosition * originalAudioBuffer.sampleRate);
                let mid_data = empty_segment_data
                let after_data = original_channel_data.subarray(Math.floor(cursorPosition * originalAudioBuffer.sampleRate), (originalAudioBuffer.length * originalAudioBuffer.sampleRate));


                new_channel_data.set(before_data);
                new_channel_data.set(mid_data, (cursorPosition * newAudioBuffer.sampleRate));
                new_channel_data.set(after_data, (cursorPosition + params.cutSelection.duration) * newAudioBuffer.sampleRate);
            }
        }

        if (params.channelStatus.left === true || params.channelStatus.right === true) {
            this.wavesurfer.clearRegions()
            this.wavesurfer.loadDecodedBuffer(newAudioBuffer);
        }

        if (callback) {
            callback();
        }
        /*this.wavesurfer.backend.buffer = newAudioBuffer;
        this.wavesurfer.drawBuffer();*/
    }
    cut(params){
        /*
        ---------------------------------------------
        The function will take the buffer used to create the waveform and will
        create
        a new blob with the selected area from the original blob using the
        offlineAudioContext
        */

        let start = params.start;
        let end = params.end;

        let originalAudioBuffer = this.wavesurfer.backend.buffer;

        let lengthInSamples = Math.floor( (end - start) * originalAudioBuffer.sampleRate );
        if (! window.OfflineAudioContext) {
            if (! window.webkitOfflineAudioContext) {
                alert('webkit context not found')
            }
            window.OfflineAudioContext = window.webkitOfflineAudioContext;
        }
        let offlineAudioContext = this.wavesurfer.backend.ac

        let emptySegment = offlineAudioContext.createBuffer(
            originalAudioBuffer.numberOfChannels,
            lengthInSamples+1, //todo: a corriger
            originalAudioBuffer.sampleRate );

        let newAudioBuffer = []
        if (params.withBlank === false && params.channelStatus.left === true && params.channelStatus.right === true) {
            newAudioBuffer = offlineAudioContext.createBuffer(
                originalAudioBuffer.numberOfChannels,
                ((originalAudioBuffer.length- lengthInSamples) + 1),
                originalAudioBuffer.sampleRate);
        }
        else {
            newAudioBuffer = offlineAudioContext.createBuffer(
                originalAudioBuffer.numberOfChannels,
                (originalAudioBuffer.length) + 1,
                originalAudioBuffer.sampleRate);
        }
        for (let channel = 0; channel < originalAudioBuffer.numberOfChannels;channel++) {

            let new_channel_data = newAudioBuffer.getChannelData(channel);
            let empty_segment_data = emptySegment.getChannelData(channel);
            let original_channel_data = originalAudioBuffer.getChannelData(channel);

            if ((params.channelStatus.left === false && channel === 0) ||
                (params.channelStatus.right === false && channel === 1)) {
                new_channel_data.set(original_channel_data)
            } else {
                let before_data = original_channel_data.slice(0, Math.floor(start * originalAudioBuffer.sampleRate));
                let mid_data = original_channel_data.slice(Math.floor(start * originalAudioBuffer.sampleRate), Math.floor(end * originalAudioBuffer.sampleRate));
                let after_data = original_channel_data.slice(Math.floor(end * originalAudioBuffer.sampleRate), Math.floor((originalAudioBuffer.length * originalAudioBuffer.sampleRate)));
                empty_segment_data.set(mid_data);

                if (start > 0) {
                    new_channel_data.set(before_data);
                    if (params.withBlank === true) {
                        //new_channel_data.set(blankSegment.getChannelData(channel), (start * newAudioBuffer.sampleRate))
                        new_channel_data.set(after_data, (end * newAudioBuffer.sampleRate));
                    } else
                        new_channel_data.set(after_data, (start * newAudioBuffer.sampleRate));
                } else {
                    if (params.withBlank === true) {
                        new_channel_data.set(after_data, (end * newAudioBuffer.sampleRate));
                    } else
                        new_channel_data.set(after_data);
                }
            }
        }

        if (params.channelStatus.left === true || params.channelStatus.right === true) {
            this.wavesurfer.clearRegions()
            this.wavesurfer.loadDecodedBuffer(newAudioBuffer);
        }
        /*console.log("num1")
        this.wavesurfer.backend.buffer = newAudioBuffer;
        this.wavesurfer.drawBuffer();
        console.log("num2")*/
        return {
            newAudioBuffer,
            cutSelection:emptySegment
        }
    }
    trim(params){
        /*
        ---------------------------------------------
        The function will take the buffer used to create the waveform and will
        create
        a new blob with the selected area from the original blob using the
        offlineAudioContext
        */
        let start = params.start;
        let end = params.end;

        let originalAudioBuffer = this.wavesurfer.backend.buffer;

        let lengthInSamples = Math.floor( (end - start) * originalAudioBuffer.sampleRate );
        if (! window.OfflineAudioContext) {
            if (! window.webkitOfflineAudioContext) {
                alert('webkit context not found')
            }
            window.OfflineAudioContext = window.webkitOfflineAudioContext;
        }
        let offlineAudioContext = this.wavesurfer.backend.ac

        let emptySegment = offlineAudioContext.createBuffer(
            originalAudioBuffer.numberOfChannels,
            lengthInSamples+1, //todo: a corriger
            originalAudioBuffer.sampleRate );

        let newAudioBuffer = offlineAudioContext.createBuffer(
            originalAudioBuffer.numberOfChannels,
            (originalAudioBuffer.length - emptySegment.length)+1,
            originalAudioBuffer.sampleRate);

        for (let channel = 0; channel < originalAudioBuffer.numberOfChannels;channel++) {

            let new_channel_data = newAudioBuffer.getChannelData(channel);
            let empty_segment_data = emptySegment.getChannelData(channel);
            let original_channel_data = originalAudioBuffer.getChannelData(channel);

            if ((params.channelStatus.left === false && channel === 0) ||
                (params.channelStatus.right === false && channel === 1)) {
                new_channel_data.set(original_channel_data)
            }
            else {
                let before_data = original_channel_data.subarray(0, Math.floor(start * originalAudioBuffer.sampleRate));
                let mid_data = original_channel_data.subarray(Math.floor(start * originalAudioBuffer.sampleRate), Math.floor(end * originalAudioBuffer.sampleRate));
                let after_data = original_channel_data.subarray(Math.floor(end * originalAudioBuffer.sampleRate), Math.floor((originalAudioBuffer.length * originalAudioBuffer.sampleRate)));
                empty_segment_data.set(mid_data);
                if (start > 0) {
                    new_channel_data.set(before_data);
                    new_channel_data.set(after_data, (start * newAudioBuffer.sampleRate));
                } else {
                    new_channel_data.set(after_data);
                }
            }
        }

        if (params.channelStatus.left === true || params.channelStatus.right === true) {
            this.wavesurfer.clearRegions()
            this.wavesurfer.loadDecodedBuffer(emptySegment);
        }

        return {
            newAudioBuffer,
            cutSelection:emptySegment
        }
    }
    copy(region, channelStatus){
        let segmentDuration = region.end - region.start

        let originalBuffer = this.wavesurfer.backend.buffer;
        let emptySegment = this.wavesurfer.backend.ac.createBuffer(
            originalBuffer.numberOfChannels,
            segmentDuration * originalBuffer.sampleRate+1,
            originalBuffer.sampleRate
        );
        for (let i = 0; i < originalBuffer.numberOfChannels; i++) {
            if ((channelStatus.left !== false || i !== 0) &&
                (channelStatus.right !== false || i !== 1)) {
                emptySegment.getChannelData(i).set(originalBuffer.getChannelData(i).subarray( region.start * originalBuffer.sampleRate, region.end * originalBuffer.sampleRate));
            }
        }
        return emptySegment
    }
    bufferToWave(abuffer, offset, len) {

        let numOfChan = abuffer.numberOfChannels,
            length = len * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [], i, sample,
            pos = 0;

        // write WAVE header
        setUint32(0x46464952);                         // "RIFF"
        setUint32(length - 8);                         // file length - 8
        setUint32(0x45564157);                         // "WAVE"

        setUint32(0x20746d66);                         // "fmt " chunk
        setUint32(16);                                 // length = 16
        setUint16(1);                                  // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(abuffer.sampleRate);
        setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2);                      // block-align
        setUint16(16);                                 // 16-bit (hardcoded in this demo)

        setUint32(0x61746164);                         // "data" - chunk
        setUint32(length - pos - 4);                   // chunk length

        // write interleaved data
        for (i = 0; i < abuffer.numberOfChannels; i++)
            channels.push(abuffer.getChannelData(i));

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {             // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
                view.setInt16(pos, sample, true);          // update data chunk
                pos += 2;
            }
            offset++                                     // next source sample
        }

        // create Blob
        return new Blob([buffer], {type: "audio/mpeg"});

        function setUint16(data) {
            view.setUint16(pos, data, true);
            pos += 2;
        }

        function setUint32(data) {
            view.setUint32(pos, data, true);
            pos += 4;
        }
    }
}