<template>
  <b-card class="w-100 h-100" no-body >
            <b-card-header class="p-0" >
              <div>
                <div class="d-flex justify-content-between">
                  <div class="w-100">
                      <div class="d-flex justify-content-between px-3" >
                        <div>
                          <div class="timer-title">{{ $t('solo_editor.timers.cursor')}}</div>
                          <div v-if="IA.my_spectrum !== null" class="timer-size">
                            {{IA.timer_print.current_time}}
                          </div>
                          <div v-else class="timer-size">-</div>
                        </div>
                        <div>
                          <div class="timer-title">{{ $t('solo_editor.timers.before_edit')}}</div>
                          <div v-if="IA.my_spectrum !== null" class="timer-size">
                            {{IA.timer_print.duration_before_edit}}
                          </div>
                          <div v-else class="timer-size">-</div>
                        </div>
                        <div>
                          <div class="timer-title">{{ $t('solo_editor.timers.total')}}</div>
                          <div v-if="IA.my_spectrum !== null" class="timer-size">
                            {{IA.timer_print.total_time}}
                          </div>
                          <div v-else class="timer-size">-</div>
                        </div>
                        <div class="selection-timer">
                          <div class="d-flex justify-content-between">
                            <div id="selection_text" class="selection-padding">
                              {{ $t('solo_editor.timers.selection')}}
                            </div>
                            <div>
                              {{ $t('solo_editor.timers.start')}}
                              <div v-if="IA.my_spectrum !== null && IA.timer.region_start !== null">
                                {{IA.timer_print.region_start}}
                              </div>
                              <div v-else>-</div>
                            </div>
                            <div>
                              {{ $t('solo_editor.timers.end')}}
                              <div v-if="IA.my_spectrum !== null && IA.timer.region_end !== null">
                                {{IA.timer_print.region_end}}
                              </div>
                              <div v-else>-</div>
                            </div>
                            <div>
                              {{ $t('solo_editor.timers.duration')}}
                              <div v-if="IA.my_spectrum !== null && IA.timer.region_start !== null">
                                {{IA.timer_print.region_duration}}
                              </div>
                              <div v-else>-</div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-text class="h-100">
              <div class="d-flex h-100">
                <audio id="binPlayer" src=""></audio>

                <div class="d-flex flex-column justify-content-around px-1" style="padding-bottom: 20px">
                    <b-btn @focus="blurMe" @click="channelButtonClick(0)"
                           class="channel-button" :variant="IA.channel_status.left ? 'danger':'secondary'">
                      <div v-if="IA.channel_status.left">{{ $t('solo_editor.on')}}</div>
                      <div  v-else>{{ $t('solo_editor.off')}}</div>
                    </b-btn>
                    <b-btn @focus="blurMe"  @click="channelButtonClick(1)"
                           class="channel-button" :variant="IA.channel_status.right ? 'danger':'secondary'">
                      <div v-if="IA.channel_status.right">{{ $t('solo_editor.on')}}</div>
                      <div v-else>{{ $t('solo_editor.off')}}</div>
                    </b-btn>
                </div>
                <div class="h-100 d-flex py-2" >
                  <div class="h-100 vu-meters mx-1">
                    <div v-bind:style="vuMeter1"></div>
                  </div>

                  <div class="h-100 vu-meters mx-1">
                    <div v-bind:style="vuMeter2"></div>
                  </div>
                </div>

                <div class="col p-0">
                  <drop class="d-flex   p-4 w-100 h-100 position-absolute align-middle drop-style" v-if="!IA.is_file_loaded"
                        @drop="loadFile">
                    <h1 class="overflow-hidden w-100 h-100 rounded d-flex  justify-content-center align-items-center">
                      <div>
                        {{$t('solo_editor.drop_file')}}
                      </div>

                    </h1>
                  </drop>
                  <div id="audio-spectrum" class="px-0">
                  </div>
                  <div id="audio-timeline">
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
</template>

<script>
    import {Drop} from "vue-easy-dnd";
    export default {
        name: 'AudioEditor',
        components: {Drop},
        methods: {
            blurMe(e) {
              e.currentTarget.blur()
            },
            channelButtonClick(channel_nbr) {
                this.$store.commit('inesAudio/channelButtonClick', channel_nbr)
            },
            loadFile(e) {
                /*let files = file.target.files || file.dataTransfer.files;
                this.$store.commit('inesAudio/loadSpectrumFromFile', files[0].name)*/
                this.$store.commit('inesAudio/createSpectrum')
                this.$store.commit('inesAudio/loadSpectrumFromFile', e.data)
            },
        },
        mounted() {
        },
        props: {
            msg: String
        },
        computed: {
            vuMeter1() {
                return {"background-color": "lightgrey", height: `${this.IA.vu_meter.height1}%`, width: "10px"};
            },
            vuMeter2() {
                return {"background-color": "lightgrey", height: `${this.IA.vu_meter.height2}%`, width: "10px"};
            },
            IA() {
                return this.$store.state.inesAudio
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.drop-style {

  z-index: 100000;
}

.drop-style h1 {
  padding: 5px;
  background-color: WhiteSmoke;
  border: LightGray 5px dashed;
}

.timer-size {
  font-size: 17pt;
}

.selection-timer {
  padding-top: 5px;
  width: 400px;
  font-size: 13pt;
}

.selection-padding {
  padding-top: 13px;
}

.channel-button {
  height: 60px;
  width: 60px;
}

.timer-title {
  font-size: 14pt;
}

.vu-meters {
  width: 10px;
  background: linear-gradient(to top,#99c2c6 0,#6ecc87 20%,#d6af22 60%,#d15110 100%);
}

</style>
